<template>
    <div class="abilityDetails">
        <!-- 标题 -->
        <div class="title">睡眠调频所</div>
        <div class="empty"></div>
        <div class="content">

            <!-- 三行文字 -->
            <topText :title="title" :subtitle="subtitle"></topText>

            <!-- 按钮 -->
            <div class="btns">
                <div v-for="(item, index) in dataList" :key="index">
                    <button :class="{ active: isActive === index }">{{ item }}</button>
                </div>
                <div class="horizontalline"></div>
            </div>
            <!-- 最下面按钮 -->
            <button class="immediate">立即改善</button>
        </div>
    </div>
</template>

<script>
import topText from './components/topText.vue'
export default {
  name: 'SleepStation',
  components: { topText },
  data () {
    return {
      dataList: ['肾上腺素', '荷尔蒙', '雌激素', '雄激素',
        '更年期', '肝脏', '心脏', '肾脏', '大脑', '肠胃', '副交感神经', '交感神经'],
      title: '睡眠调频所',
      subtitle: '疗愈压力与情绪失眠',
      isActive: 2
    }
  },
  methods: {

  },
  mounted () {
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.abilityDetails {
    // height: 100%;
    background: linear-gradient(rgba(153, 102, 255, 0.6) 100%, rgba(153, 102, 255, 0.5)100%), url("https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/sleepStation.png");
    background-size: 100%;
    background-color: #FFF;
    color: #4f4f4f;
    font-size: 23px;

    .title {
        text-align: center;
        color: #fff;
        padding: 20px;
    }

    .empty {
        width: 150px;
        height: 18px;
        background: #FF8563;
        border-radius: 10px;
        margin: 160px 10px 0 120px;
    }

    .content {
        width: 100%;
        border-radius: 30px;
        background: #fff;
        margin-top: -10px;
        color: #808080;

        .btns {
            margin: 20px;
            width: 400px;
            display: flex;
            flex-wrap: wrap;

            button {
                width: 150px;
                height: 40px;
                font-size: 18px;
                margin: 10px 0 10px 10px;
                border-radius: 20px;
                border: 1px solid #ccc;
                background: #F7F8F8;
            }

            .active {
                background: #FFCEC7;
            }

            .horizontalline {
                width: 300px;
                border-bottom: 2px dashed;
                margin: 30px 15px;
            }
        }

        .immediate {
            width: 180px;
            height: 60px;
            margin: 10px 100px;
            font-size: 25px;
            border: 1px solid #ccc;
            border-radius: 30px;
            background: #ffaa8f;
            color: #fff;
        }

    }
}
</style>
